import React from "react";
import { graphql } from "gatsby";
import { Container } from "../components/container";
import GraphQLErrorList from "../components/others/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { toPlainText } from "../lib/helpers";

import BlogPost from "../components/blog/blog-post";

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        name
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
      route: sanityRoute {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
        }
      }
  }
`;

const BlogPostTemplate = (props) => {
    const { data, errors } = props;
    const post = data && data.post;
  
    const page = data.page || data.route.page;
    const menuItems = page.navMenu && (page.navMenu.items || []);
  
    return (
      <Layout navMenuItems={menuItems}>
        {errors && <SEO title="GraphQL Error" />}
        {post && (
          <SEO
            title={post.title || "Untitled"}
            description={toPlainText(post._rawExcerpt)}
            image={post.mainImage}
          />
        )}
  
        {errors && (
         <Container>
            <GraphQLErrorList errors={errors} />
         </Container>
        )}
  
        {post && <BlogPost {...post} />}
      </Layout>
    );
  };
  
  export default BlogPostTemplate;